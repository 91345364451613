import Rails from '@rails/ujs'
import _     from 'underscore'

import dateToString      from 'lib/date_to_string'

import ApplicationController    from 'controllers/application_controller'
import RealtimeWidgetController from 'controllers/realtime_widget_controller'
import GuestsSelectController   from 'controllers/realtime_widget/guests_select_controller'
import DateSelectController     from 'controllers/realtime_widget/date_select_controller'
import TimeSelectController     from 'controllers/realtime_widget/time_select_controller'
import BookableSelectController from 'controllers/realtime_widget/bookable_select_controller'
import DepositController        from 'controllers/realtime_widget/deposit_controller'

export default class extends ApplicationController {
  static values = {
    id:              Number,
    label:           String,
    realtime:        Boolean,
    reject:          Boolean,
    squeeze:         Boolean,
    availableFrom:   String,
    availableTill:   String,
    availabilityUrl: String,
    availabilityUrlTemplate: String,
    minimumGuests:   { type: Number, default: 0               },
    maximumGuests:   { type: Number, default: 0               },
    availabilities:  { type: Array,  default: []              },
    upcomingDays:    { type: Array,  default: []              },
    weekdays:        { type: Array,  default: [1,1,1,1,1,1,1] },
    closedDates:     { type: Array,  default: []              },
    openDates:       { type: Array,  default: []              },
    openingHours:    { type: Object, default: {}              }
  }

  static targets = ['cover']

  static classes = ['disabled', 'selected']

  static outlets = [
    'realtime-widget',
    'realtime-widget--guests-select',
    'realtime-widget--bookable-select',
    'realtime-widget--date-select',
    'realtime-widget--time-select',
    'realtime-widget--deposit'
  ]

  get disabledDays() {
    return _.map(this.weekdaysValue, (x) => (x == 1) ? 0 : 1)
  }

  get timeslots() {
    return this.realtimeWidgetOutlet.timeslotsValue
  }

  // OUTLETS:

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  /**
   * @returns {GuestsSelectController}
   */
  get guestsSelect() {
    return this.realtimeWidgetGuestsSelectOutlet
  }

  /**
   * @returns {DateSelectController}
   */
  get dateSelect() {
    return this.realtimeWidgetDateSelectOutlet
  }

  /**
   * @returns {TimeSelectController}
   */
  get timeSelect() {
    return this.realtimeWidgetTimeSelectOutlet
  }

  /**
   * @returns {BookableSelectController}
   */
  get bookableSelect() {
    return this.realtimeWidgetBookableSelectOutlet
  }

  get radioInput () {
    return this.wrapper.querySelector('input[type=radio]')
  }

  get wrapper () {
    return this.element.parentElement.parentElement
  }

  /**
   * @returns {DepositController}
   */
  get deposit() {
    if (this.hasRealtimeWidgetDepositOutlet) {
      return this.realtimeWidgetDepositOutlet
    }
  }

  // VALUES:

  get guests() {
    let guestsValue = this.guestsSelect.value

    if (this.minimumGuestsValue > 0 && guestsValue < this.minimumGuestsValue) {
      guestsValue = this.minimumGuestsValue
    }

    if (this.maximumGuestsValue > 0 && guestsValue > this.maximumGuestsValue) {
      guestsValue = this.maximumGuestsValue
    }

    return guestsValue
  }

  get date() {
    return this.realtimeWidgetDateSelectOutlet.value
  }

  get time() {
    return this.realtimeWidgetTimeSelectOutlet.value
  }

  get isSelected() {
    return this.element.classList.contains(this.selectedClass)
  }

  // CALLBACKS:

  availabilitiesValueChanged (newValue, oldValue) {
    if (newValue && newValue.length) {
      this.widget.refresh()
    }
  }

  // EVENTS:

  connect () {
    this.availabilityRequestUpdated = this.availabilityRequestUpdated.bind(this)

    if (this.hasAvailabilityUrlValue) {
      _.defer(() => this.fetchAvailability())
    }
  }

  select (event) {
    this.element.classList.add(this.selectedClass)

    let availableDates = this.datesAvailable
    if (availableDates.length == 1) {
      this.dateSelect.value = null
      this.dateSelect.value = availableDates[0]
    } else if (this.isDisabledDate()) {
      this.dateSelect.value = null
    }

    this.dateSelect.availableFromValue = this.availableFromValue
    this.dateSelect.availableTillValue = this.availableTillValue

    this.widget.refresh()
  }

  unselect() {
    this.element.classList.remove(this.selectedClass)
  }

  enable() {
    this.element.classList.remove(this.disabledClass)

    if (this.radioInput) {
      this.radioInput.removeAttribute('disabled')
    }

    // TODO: Refactor this:
    this.wrapper.classList.remove(this.disabledClass)
  }

  disable() {
    this.element.classList.add(this.disabledClass)

    if (this.radioInput) {
      this.radioInput.setAttribute('disabled', true)
    }

    this.wrapper.classList.add(this.disabledClass)
  }

  toggleAvailability() {
    let seatsAvailable = this.seatsAvailableForAllDates
    let selectedGuests = this.guests

    if (selectedGuests && (selectedGuests < seatsAvailable.minimum || selectedGuests > seatsAvailable.maximum)) {
      this.disable()
    } else {
      this.enable()
    }
  }

  // REFRESH:

  refresh() {
    if (this.date) {
      this.fetchRangedAvailability(this.date, this.date, true)
    }

    this.dateSelect.refresh()
    this.refreshSelectedDate()
  }

  refreshSelectedDate() {
    this.timeSelect.refreshTimeslots()
    this.guestsSelect.refreshGuests()
    this.bookableSelect.refreshBookables()
    this.updateDepositDescription()
  }

  updateDepositDescription() {
    if (this.deposit) {
      this.deposit.updateDescription()
    }
  }

  // AVAILABILITY REQUESTS:

  fetchAvailability() {
    if (!this.request) {
      let request = this.request = new XMLHttpRequest()

      request.open('GET', this.availabilityUrlValue)

      request.setRequestHeader('Content-Type', 'application/json')
      request.setRequestHeader('X-CSRF-Token', Rails.csrfToken())
      request.setRequestHeader('Accept',       'application/json, text/javascript')

      request.onreadystatechange = this.availabilityRequestUpdated

      request.send()
    } else {
      this.request.onreadystatechange = null
      this.request.abort()
      this.request = null
      this.fetchAvailability()
    }
  }

  fetchRangedAvailability(from, till, forceRequest = false) {
    let maxAvailabilityDate = this.maxAvailabilityDate
    let performRequest = maxAvailabilityDate && maxAvailabilityDate < till

    if (!this.request && (forceRequest || performRequest)) {
      let fromParam  = from.toISOString().split('T')[0]
      let tillParam  = till.toISOString().split('T')[0]
      let requestURL = this.render(this.availabilityUrlTemplateValue, { from: fromParam, till: tillParam })

      let request = this.request = new XMLHttpRequest()

      request.open('GET', requestURL)

      request.setRequestHeader('Content-Type', 'application/json')
      request.setRequestHeader('X-CSRF-Token', Rails.csrfToken())
      request.setRequestHeader('Accept',       'application/json, text/javascript')

      request.onreadystatechange = this.availabilityRequestUpdated

      request.send()
    }
  }

  availabilityRequestUpdated(event) {
    let request = this.request
    let status  = request.status

    if (request.readyState === XMLHttpRequest.DONE) {
      if (status === 0 || (status >= 200 && status < 400)) {
        let response = JSON.parse(request.responseText)
        this.processResponse(response)
      } else {
        console.log('error requesting availability', request)
      }

      this.request = null
    }
  }

  processResponse (response) {
    let availabilities = response.availabilities

    if (this.hasAvailabilitiesValue) {
      this.availabilitiesValue.forEach(
        (availability) => {
          if (!availabilities.find((existing) => existing.date == availability.date)) {
            availabilities.push(availability)
          }
        }
      )

      if (JSON.stringify(this.availabilitiesValue) != JSON.stringify(availabilities)) {
        this.availabilitiesValue = availabilities
      }
    } else {
      this.availabilitiesValue = availabilities
    }
  }

  // AVAILABILITY:

  get availability() {
    return this.availabilityForDate()
  }

  get maxAvailabilityDate() {
    if (!this.hasAvailabilitiesValue && this.availabilitiesValue.length == 0) {
      return null
    }

    let dates = this.availabilitiesValue.map((a) => a.date).sort()
    return new Date(Date.parse(dates[dates.length - 1]))
  }

  get canConfirmRealtime() {
    if (this.realtimeValue && this.date && this.time && this.guests) {
      return this.timeslotAvailable()
    }

    return false
  }

  get isSqueezed() {
    return this.canConfirmRealtime && this.squeezeValue && this.timeslotSqueezed()
  }

  get squeezedStartTime() {
    return this.time
  }

  get squeezedEndTime() {
    let guests         = this.guests
    let availability   = this.availability
    let seatsAvailable = availability.squeezedSeatsAvailable.maximum

    let timeslot   = null
    let startFound = false
    let endFound   = false

    for (let [index, capacity] of seatsAvailable.entries()) {
      timeslot = availability.timeslots[index]

      if (timeslot === this.time)          { startFound = true }
      if (startFound && capacity < guests) { endFound   = true }
      if (startFound && endFound)          { return timeslot   }
    }

    return timeslot
  }

  get datesAvailable() {
    return this.availabilitiesValue.filter(
      (availability) => availability.timeslots.length >= 1
    ).map(
      (availability) => availability.date
    )
  }

  get seatsAvailableForAllDates() {
    let seatsAvailable = {
      minimum: this.minimumGuestsValue,
      maximum: this.maximumGuestsValue
    }

    if (this.hasCapacityValue) {
      let ranges  = this.capacityValue.map((availability) => availability.range)
      seatsAvailable.minimum = _.min(ranges.map((range) => range[0]))
      seatsAvailable.maximum = _.max(ranges.map((range) => range[1]))
    }

    return seatsAvailable
  }

  availabilityForDate(date = this.date) {
    if (date) {
      date = dateToString(date)
      return this.availabilitiesValue.find((availability) => availability.date === date)
    }
  }

  isUnavailableDate(date = this.date) {
    let string = dateToString(date)

    if (this.availableFromValue && string < this.availableFromValue) { return true }
    if (this.availableTillValue && string > this.availableTillValue) { return true }
  }


  isConfirmableDate(date = this.date) {
    if (!this.realtimeValue || this.isUnavailableDate(date)) {
      return false
    }

    let availability   = this.availabilityForDate(date)
    let numberOfGuests = this.guests

    if (availability && availability.timeslots.length) {
      return availability.timeslots.find(
        (timeslot) => {
          let forTimeslot = this.availabilityForTimeslot(timeslot, availability)
          return (
            (
              numberOfGuests >= forTimeslot.normal.minimum &&
              numberOfGuests <= forTimeslot.normal.maximum
            ) || (
              numberOfGuests >= forTimeslot.squeezed.minimum &&
              numberOfGuests <= forTimeslot.squeezed.maximum
            )
          )
        }
      )
    }

    return false
  }

  isDisabledDate(date = this.date) {
    if (this.isUnavailableDate(date)) { return true }

    let availability = this.availabilityForDate(date)
    if (availability) {
      if (availability.timeslots.length === 0) {
        return true
      }

      if (this.rejectValue) {
        let minSeats = 0
        let maxSeats = 0

        if (availability) {
          minSeats = availability.range[0]
          maxSeats = availability.range[1]
        }

        return maxSeats < this.guests || minSeats > this.guests
      }
    } else {
      if (date && this.hasWeekdaysValue && this.weekdaysValue.length) {
        let weekDay = date.getDay()
        weekDay = (weekDay === 0) ? 6 : weekDay - 1
        if (this.weekdaysValue[weekDay] == 0) {
          return true
        }
      }
    }

    return false
  }

  get emptyAvailability() {
    return {
      squeezed: { minimum: 0, maximum: 0 },
      normal:   { minimum: 0, maximum: 0 }
    }
  }

  availabilityForTimeslot (timeslot, availability = this.availability) {
    if (!availability) {
      return this.emptyAvailability
    }

    let index = availability.timeslots.indexOf(timeslot)

    if (index === -1) {
      console.warn('Timeslot not found in availability:', timeslot)
      return this.emptyAvailability
    }

    return {
      squeezed: {
        minimum: availability.squeezedSeatsAvailable.minimum[index],
        maximum: availability.squeezedSeatsAvailable.maximum[index]
      },
      normal: {
        minimum: availability.seatsAvailable.minimum[index],
        maximum: availability.seatsAvailable.maximum[index]
      }
    }
  }

  seatsAvailableForTimeslot(timeslot, squeezed = false, availability = this.availability, maximum = true) {
    let result = this.availabilityForTimeslot(timeslot, availability)
    let values = squeezed ? result.squeezed : result.normal

    return maximum ? values.maximum : values.minimum
  }

  minimumSeatsAvailableForTimeslot(timeslot = this.time, squeezed = false, availability = this.availability) {
    return this.seatsAvailableForTimeslot(timeslot, squeezed, availability, false)
  }

  maximumSeatsAvailableForTimeslot(timeslot = this.time, squeezed = false, availability = this.availability) {
    return this.seatsAvailableForTimeslot(timeslot, squeezed, availability, true)
  }

  timeslotAvailable(timeslot = this.time, availability = this.availability) {
    if (!availability) {
      if (this.date) {
        availability = this.availability
      } else {
        return false
      }
    }

    let result = this.availabilityForTimeslot(timeslot, availability)

    return (
      result.normal.maximum   >= this.guests ||
      result.squeezed.maximum >= this.guests
    ) && (
      result.normal.minimum   <= this.guests ||
      result.squeezed.minimum <= this.guests
    )
  }

  timeslotSqueezed(timeslot = this.time) {
    let result = this.availabilityForTimeslot(timeslot)

    return (
      result.normal.maximum   <  this.guests &&
      result.squeezed.maximum >= this.guests
    )
  }
}
