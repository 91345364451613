import _ from 'underscore'
import dateToString from 'lib/date_to_string'
import ApplicationController from 'controllers/application_controller'
import BookableSelectController from 'controllers/realtime_widget/bookable_select_controller'
import BookableController from 'controllers/realtime_widget/bookable_controller'

export default class extends ApplicationController {

  // SETUP:

  static targets = [
    'stepOne',
    'stepTwo',
    'nextStepButton',
    'squeezeInfo',
    'squeezeInput',
    'squeezeFrom',
    'squeezeTill',
    'availabilityInfo',
    'guestsStatus',
    'dateStatus',
    'timeStatus',
    'debugStatus',
    'showDepositDescription',
    'depositDescription',
    'depositNumberOfGuests',
    'depositTotalCost',
    'depositCostPerPerson',
    'depositWarning',
    'reservationPayment',
  ]

  static values = {
    // maximumGroupSize is currently not used
    maximumGroupSize: Number,
    stepOneTitle:     { type: String, default: 'Reserve a table'       },
    stepTwoTitle:     { type: String, default: 'Enter contact details' },
    stepThreeTitle:   { type: String, default: 'Reservation details'   },
    currentStep:      { type: Number, default: 1 },
    timeslots:        { type: Array, default: [] }
  }

  static classes = [
    'disabled',
    'isActive',
    'reservationsWidgetStepped',
    'current',
    'isVisible',
    'isHidden'
  ]

  static outlets = [
    'realtime-widget--bookable-select',
  ]

  /**
   * @returns {BookableSelectController}
   */
  get bookableSelect()  {
    return this.realtimeWidgetBookableSelectOutlet
  }

  /**
   * @returns {BookableController}
   */
  get booking() {
    return this.bookableSelect && this.bookableSelect.booking
  }

  // INITIALIZATION:

  connect() {
    _.defer(() => {
      this.initializeWidget()
    })
  }

  disconnect() {
    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }

  initializeWidget() {
    this.showCurrentStep()

    if (this.embedded) {
      this.embedded.onExpand(this.resetWidgetState.bind(this))
    }
  }

  // REFRESH:

  refresh() {
    if (!this.booking) {
      return
    }

    this.booking.refresh()

    if (this.currentStepValue < 3) {
      this.toggleNextStep()

      this.dateStatusTarget.innerText   = this.booking.dateSelect.humanValue
      this.timeStatusTarget.innerText   = this.booking.timeSelect.value
      this.guestsStatusTarget.innerText = this.booking.guestsSelect.label

      this.refreshSqueeze()
      this.refreshRealtime()
      this.refreshDebug()

      if (this.embedded) {
        this.embedded.reportWidgetSize()
      }
    }
  }

  refreshSqueeze() {
    if (this.hasSqueezeInputTarget) {
      this.squeezeInputTarget.value = 'off'
      this.squeezeInfoTarget.classList.remove(this.isActiveClass)

      if (this.booking.isSqueezed) {
        this.squeezeInputTarget.value = 'on'
        this.squeezeInfoTarget.classList.add(this.isActiveClass)

        this.morph(this.squeezeFromTarget, this.booking.squeezedStartTime)
        this.morph(this.squeezeTillTarget, this.booking.squeezedEndTime)
      }
    }
  }

  refreshRealtime() {
    if (this.booking.canConfirmRealtime) {
      if (this.hasAvailabilityInfoTarget) {
        this.availabilityInfoTarget.classList.add(this.isActiveClass)
      }
    } else {
      if (this.hasAvailabilityInfoTarget) {
        this.availabilityInfoTarget.classList.remove(this.isActiveClass)
      }

      if (this.booking.rejectValue) {
        this.nextStepButtonTarget.classList.add(this.disabledClass)
      }
    }
  }

  refreshDebug() {
    if (this.hasDebugStatusTarget) {
      let booking       = this.booking
      let guests        = booking.guests
      let date          = booking.date && booking.date.toDateString()
      let time          = booking.time
      let availability  = booking.availability
      let timeslots     = JSON.stringify(availability && availability.timeslots)
      let normalSeats   = JSON.stringify(availability && availability.seatsAvailable && availability.seatsAvailable.maximum)
      let squeezedSeats = JSON.stringify(availability && availability.squeezedSeatsAvailable && availability.squeezedSeatsAvailable.maximum)

      this.morph(this.debugStatusTarget, [
        `random: ${Math.round(Math.random() * 1000)}`,
        `step: ${this.currentStepValue}`,
        `time: ${time}`,
        `guests: ${guests}`,
        `date: ${date}`,
        `is unavailable date: ${booking.isUnavailableDate()}`,
        `is disabled date: ${booking.isDisabledDate()}`,
        `realtime: ${booking.realtimeValue ? 'true' : 'false'}, reject: ${booking.rejectValue ? 'true' : 'false'}`,
        `maximum: ${booking.maximumSeatsAvailableForTimeslot()}, squeezed: ${booking.maximumSeatsAvailableForTimeslot(time, true)}`,
        `minimum: ${booking.minimumSeatsAvailableForTimeslot()}, squeezed: ${booking.minimumSeatsAvailableForTimeslot(time, true)}`,
        `can confirm: ${booking.canConfirmRealtime}, is squeezed: ${booking.isSqueezed}`,
        `bookable: ${this.bookableSelect ? this.bookableSelect.label || 'no' : 'disabled'}`,
        `timeslots: ${timeslots}`,
        `availabilities: ${booking.availabilitiesValue && booking.availabilitiesValue.length}`,
        `normal seats:\n${normalSeats}\n`,
        `squeezed seats:\n${squeezedSeats}\n`
      ].join("\n"))
    }
  }

  // MULTI STEPS:

  resetWidgetState () {
    if (this.booking.dateSelect) {
      this.booking.dateSelect.closeDatePicker()
    }

    if (this.hasStepOneTarget) {
      this.firstStep()
    }
  }

  toggleNextStep() {
    this.nextStepButtonTarget.classList.remove(this.disabledClass)

    const disable = (
      !this.booking.dateSelect.value   || !this.booking.timeSelect.value  ||
      !this.booking.guestsSelect.value || this.bookableSelect.needsSelection
    )

    if (disable) {
      this.nextStepButtonTarget.classList.add(this.disabledClass)
    }
  }

  firstStep() {
    this.currentStepValue = 1
    this.showCurrentStep()
  }

  previousStep(event) {
    if (event) { event.preventDefault() }
    this.firstStep()
  }

  nextStep (event) {
    if (event) { event.preventDefault() }

    if (!this.nextStepButtonTarget.classList.contains(this.disabledClass)) {
      this.currentStepValue = 2
      this.showCurrentStep()
    }
  }

  showCurrentStep() {
    this.element.classList.add(this.reservationsWidgetSteppedClass)

    let title = null

    if (this.currentStepValue === 2) {
      this.stepOneTarget.classList.remove(this.currentClass)
      this.stepTwoTarget.classList.add(this.currentClass)

      title = this.stepTwoTitleValue
    } else if (this.currentStepValue === 3) {
      title = this.stepThreeTitleValue
    } else {
      this.stepOneTarget.classList.add(this.currentClass)
      this.stepTwoTarget.classList.remove(this.currentClass)

      title = this.stepOneTitleValue
    }

    if (this.embedded) {
      this.embedded.reportWidgetSize()

      if (title) {
        this.embedded.title = title
      }
    }
  }

  showDepositDescription (event) {
    if (event) { event.preventDefault() }

    this.showDepositDescriptionTarget.classList.add(this.isHiddenClass)
    this.depositDescriptionTarget.classList.remove(this.isHiddenClass)
  }
}
